import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { AlertAssingOrder, MenuDelivery } from "@temp/components";
import { useOrdersInProgressByUser } from "@saleor/sdk/";
import { perPage } from "@temp/constants";
import { updateAllDataInProgress, useAppDispatch, useAppSelector } from "@temp/redux";
import { useNavigate } from "react-router-dom";
import { OrderTableDelivery } from "@app/components/organisms";
import { OrderTabelDeliverySkeleton } from "@app/components/molecules";

var withOutOrdersImg = require("../../images/delivery/withOutOrders.svg");

var assingOrder = require("../../images/delivery/assingOrder.svg");

var ORDERS_PER_APICALL = +perPage;
var inProgressState = ["SENT", "ARRIVED"];

var View = function View() {
  var _data$edges, _data$edges$, _data$edges$$node, _data$edges$2, _data$edges$2$node, _data$edges2, _data$edges2$node, _data$edges3, _data$edges3$node, _deliveryOrderInProgr, _deliveryOrderInProgr2;

  var dispatch = useAppDispatch();
  var navigate = useNavigate();
  React.useEffect(function () {
    localStorage.removeItem("checkout-modify");
    localStorage.removeItem("flag_progress");
    localStorage.removeItem("flag_basket");
    localStorage.removeItem("flag_history");
    localStorage.removeItem("flag_address");
    localStorage.removeItem("sale_url");
    localStorage.removeItem("salesubcategory_url");
    localStorage.removeItem("categories_url");
    localStorage.removeItem("category_url");
    localStorage.removeItem("subcategory_url");
    localStorage.removeItem("product_url");
    localStorage.removeItem("home_url");
    return function () {};
  }, []);

  var _useAppSelector = useAppSelector(function (store) {
    return store;
  }),
      deliveryOrderInProgress = _useAppSelector.deliveryOrderInProgress;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loadingSkeleton = _React$useState2[0],
      setLoadingSkeleton = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      openAlertAssingOrder = _React$useState4[0],
      setOpenAlertAssingOrder = _React$useState4[1];

  var _useOrdersInProgressB = useOrdersInProgressByUser({
    perPage: ORDERS_PER_APICALL
  }, {
    fetchPolicy: "no-cache"
  }),
      data = _useOrdersInProgressB.data,
      loading = _useOrdersInProgressB.loading,
      loadMore = _useOrdersInProgressB.loadMore,
      refetch = _useOrdersInProgressB.refetch;

  var lenghtData = data === null || data === void 0 ? void 0 : (_data$edges = data.edges) === null || _data$edges === void 0 ? void 0 : _data$edges.length;
  React.useEffect(function () {
    if ((data === null || data === void 0 ? void 0 : data.edges.length) > 0 && !loading) {
      var newOrders = data === null || data === void 0 ? void 0 : data.edges.filter(function (order) {
        var _order$node, _order$node$status;

        return inProgressState.includes(order === null || order === void 0 ? void 0 : (_order$node = order.node) === null || _order$node === void 0 ? void 0 : (_order$node$status = _order$node.status) === null || _order$node$status === void 0 ? void 0 : _order$node$status.toUpperCase());
      });
      dispatch(updateAllDataInProgress({
        data: newOrders
      }));
    } else if ((data === null || data === void 0 ? void 0 : data.edges.length) === 0 && !loading) {
      dispatch(updateAllDataInProgress({
        data: []
      }));
    }
  }, [loading, lenghtData, data === null || data === void 0 ? void 0 : (_data$edges$ = data.edges[0]) === null || _data$edges$ === void 0 ? void 0 : (_data$edges$$node = _data$edges$.node) === null || _data$edges$$node === void 0 ? void 0 : _data$edges$$node.number, data === null || data === void 0 ? void 0 : (_data$edges$2 = data.edges[0]) === null || _data$edges$2 === void 0 ? void 0 : (_data$edges$2$node = _data$edges$2.node) === null || _data$edges$2$node === void 0 ? void 0 : _data$edges$2$node.status, data === null || data === void 0 ? void 0 : (_data$edges2 = data.edges[lenghtData - 1]) === null || _data$edges2 === void 0 ? void 0 : (_data$edges2$node = _data$edges2.node) === null || _data$edges2$node === void 0 ? void 0 : _data$edges2$node.number, data === null || data === void 0 ? void 0 : (_data$edges3 = data.edges[lenghtData - 1]) === null || _data$edges3 === void 0 ? void 0 : (_data$edges3$node = _data$edges3.node) === null || _data$edges3$node === void 0 ? void 0 : _data$edges3$node.status]);
  React.useEffect(function () {
    if (location.search.includes("order-in-progress")) {
      refetch({
        perPage: ORDERS_PER_APICALL
      });
      navigate(location.pathname, {
        replace: true
      });
    }
  }, [location.search]);
  React.useEffect(function () {
    if (!loading && loadingSkeleton) {
      setLoadingSkeleton(false);
    }
  }, [loading, loadingSkeleton]);
  React.useLayoutEffect(function () {
    var timer = setTimeout(function () {
      if (!!localStorage.getItem("scroll_order_home_delivery") && !!localStorage.getItem("status_page_inprogress_delivery") && localStorage.getItem("status_page_inprogress_delivery") === "true") {
        window.scrollTo(0, Number(localStorage.getItem("scroll_order_home_delivery")));
      }
    }, 0);
    return function () {
      clearTimeout(timer);
      localStorage.removeItem("status_page_inprogress_delivery");
      localStorage.setItem("scroll_order_home_delivery", "".concat(scrollY));
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(MenuDelivery, {
    inProgressAmount: deliveryOrderInProgress === null || deliveryOrderInProgress === void 0 ? void 0 : deliveryOrderInProgress.edges.length,
    handleInProgressRefetch: function handleInProgressRefetch() {
      refetch({
        perPage: ORDERS_PER_APICALL
      });
      setLoadingSkeleton(true);
    }
  }), loading && !data ? React.createElement("div", {
    className: "loader"
  }, React.createElement(Loader, null)) : React.createElement("div", {
    className: "HomeDelivery"
  }, (deliveryOrderInProgress === null || deliveryOrderInProgress === void 0 ? void 0 : (_deliveryOrderInProgr = deliveryOrderInProgress.edges) === null || _deliveryOrderInProgr === void 0 ? void 0 : _deliveryOrderInProgr.length) === 0 && loading || loadingSkeleton ? React.createElement("div", {
    className: "HomeDelivery__table"
  }, React.createElement(OrderTabelDeliverySkeleton, null)) : (deliveryOrderInProgress === null || deliveryOrderInProgress === void 0 ? void 0 : (_deliveryOrderInProgr2 = deliveryOrderInProgress.edges) === null || _deliveryOrderInProgr2 === void 0 ? void 0 : _deliveryOrderInProgr2.length) === 0 && !loading ? React.createElement("div", {
    className: "HomeDelivery__image"
  }, React.createElement(ReactSVG, {
    path: withOutOrdersImg
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.HomeeDelivery.View.2887726835",
    defaultMessage: "you currently have no assigned orders"
  }))) : React.createElement("div", {
    className: "HomeDelivery__table"
  }, React.createElement(OrderTableDelivery, {
    data: deliveryOrderInProgress,
    dataLoadMore: data,
    inProgressFlag: true,
    loading: loading,
    loadMore: loadMore,
    deliveryMan: true
  })), React.createElement(Button, {
    className: "assignOrderContainerMobile",
    testingContext: "assignOrder",
    onClick: function onClick() {
      return setOpenAlertAssingOrder(true);
    }
  }, React.createElement(ReactSVG, {
    path: assingOrder
  })), openAlertAssingOrder && React.createElement(AlertAssingOrder, {
    hideModal: function hideModal() {
      return setOpenAlertAssingOrder(false);
    }
  })));
};

export default View;