export var ObligatoryFieldEnum;

(function (ObligatoryFieldEnum) {
  ObligatoryFieldEnum["IDENTITY_CARD"] = "IDENTITY_CARD";
  ObligatoryFieldEnum["PAYMENT_CONFIRMATION_CODE"] = "PAYMENT_CONFIRMATION_CODE";
  ObligatoryFieldEnum["PAYMENT_CONFIRMATION_FILE"] = "PAYMENT_CONFIRMATION_FILE";
  ObligatoryFieldEnum["IDENTITY_CARD_TYPE"] = "IDENTITY_CARD_TYPE";
  ObligatoryFieldEnum["EMAIL"] = "EMAIL";
})(ObligatoryFieldEnum || (ObligatoryFieldEnum = {}));

export var OrderStatus;

(function (OrderStatus) {
  OrderStatus["ARRIVED"] = "ARRIVED";
  OrderStatus["CANCELED"] = "CANCELED";
  OrderStatus["CANCELED_FULFILLMENT"] = "CANCELED_FULFILLMENT";
  OrderStatus["DELIVERED"] = "DELIVERED";
  OrderStatus["DRAFT"] = "DRAFT";
  OrderStatus["DRAFT_NEW_ORDER"] = "DRAFT_NEW_ORDER";
  OrderStatus["FULFILLED"] = "FULFILLED";
  OrderStatus["PENDING"] = "PENDING";
  OrderStatus["SENT"] = "SENT";
  OrderStatus["PARTIALLY_FULFILLED"] = "PARTIALLY_FULFILLED";
  OrderStatus["UNDELIVERED"] = "UNDELIVERED";
  OrderStatus["UNFULFILLED"] = "UNFULFILLED";
  OrderStatus["TIMEOUT"] = "TIMEOUT";
})(OrderStatus || (OrderStatus = {}));

export var PaymentMethodTypesEnum;

(function (PaymentMethodTypesEnum) {
  PaymentMethodTypesEnum["CASH_GATEWAY"] = "mirumee.payments.cash";
  PaymentMethodTypesEnum["CARD_GATEWAY"] = "mirumee.payments.dataphone";
  PaymentMethodTypesEnum["NEQUI_GATEWAY"] = "mirumee.payments.nequi";
})(PaymentMethodTypesEnum || (PaymentMethodTypesEnum = {}));

export var UserRoleEnum;

(function (UserRoleEnum) {
  UserRoleEnum["SUPER_ADMIN"] = "SUPER_ADMIN";
  UserRoleEnum["STAFF"] = "STAFF";
  UserRoleEnum["MAINTRADE_STAFF"] = "MAINTRADE_STAFF";
  UserRoleEnum["TRADE_STAFF"] = "TRADE_STAFF";
  UserRoleEnum["CUSTOMER"] = "CUSTOMER";
  UserRoleEnum["DELIVERY_MAN"] = "DELIVERY_MAN";
})(UserRoleEnum || (UserRoleEnum = {}));

export var PermissionEnum;

(function (PermissionEnum) {
  PermissionEnum["FULFILLMENT_SELF_ASSIGN"] = "FULFILLMENT_SELF_ASSIGN";
})(PermissionEnum || (PermissionEnum = {}));